import React, { useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import FormattedTextField from "../../ReactHookForm/FormattedTextField";
import Select from "../../ReactHookForm/Select";
import { Checkbox, FormControlLabel, TextareaAutosize } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { TerminalContext } from "./TerminalContext";
import { GatewayConfigContext } from "../../../contexts/GatewayConfigContext";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { formatCurrency } from "../../../utils/stringHelpers";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const PaymentDetails = () => {
  // Contexts
  const form = useFormContext();
  const gatewayInfo = useContext(GatewayContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const { paymentType, setPaymentType, taxRules, setTaxRules } =
    useContext(TerminalContext);
  const taxRate =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Merchant",
      "tax_rate",
    ) || null;
  // States
  const amount = useWatch({ name: "amount", defaultValue: 0 });

  const showVerification = () => {
    return gatewayInfo.processors.some((p) => {
      return p.processorType.type === "CreditCard" && !p.isDeleted;
    });
  };

  return (
    <div>
      <h2 className="h4">Payment Details</h2>
      <div>
        <div className="button-toggle">
          <ToggleButtonGroup
            value={paymentType}
            exclusive
            onChange={(e) => {
              setPaymentType(e?.target?.value);
              if (e === "verification") {
                form.setValue("amount", "100");
              }
            }}
          >
            <ToggleButton value="sale">Sale</ToggleButton>
            <ToggleButton value="auth">Auth</ToggleButton>
            {showVerification() && (
              <ToggleButton value="verification">Verification</ToggleButton>
            )}
            {gatewayInfo.gatewaySettings
              .find((s) => s.code === "SUPPORT_BLIND_CREDITS")
              ?.value.toLowerCase() === "true" && (
              <ToggleButton value="credit">Credit</ToggleButton>
            )}
          </ToggleButtonGroup>
        </div>
        <div className="pricing-section">
          <div className="input-wrapper">
            <div>
              <div className="combination-inputs">
                <Select
                  name={`currency`}
                  control={form.control}
                  label="Amount"
                  defaultValue={"USD"}
                  rules={{ required: true }}
                  disabled={true}
                  errors={form.formState.errors}
                  v2={true}
                >
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
                <FormattedTextField
                  type="currency"
                  name="amount"
                  defaultValue={"0.00"}
                  disabled={paymentType === "verification"}
                  rules={{
                    min: { value: 1 },
                    max: {},
                    required: true,
                  }}
                />
              </div>
            </div>

            <div className="tax-section">
              {taxRate && !taxRules.taxToTotal && taxRate > 0 && (
                <FormControlLabel
                  name={"taxExempt"}
                  control={
                    <Checkbox
                      color="secondary"
                      value={taxRules.taxExempt}
                      onClick={(e) => {
                        setTaxRules({
                          taxExempt: e?.target?.checked,
                          taxToTotal: e?.target?.checked
                            ? false
                            : taxRules.taxToTotal,
                        });
                      }}
                    />
                  }
                  label={"Tax Exempt"}
                />
              )}
              {!taxRules.taxExempt && (
                <FormControlLabel
                  name={"addTax"}
                  control={
                    <Checkbox
                      color="secondary"
                      value={taxRules.taxToTotal}
                      checked={taxRules.taxToTotal}
                      onClick={(e) => {
                        setTaxRules({
                          taxExempt: e?.target?.checked
                            ? false
                            : taxRules.taxExempt,
                          taxToTotal: e?.target?.checked,
                        });
                      }}
                    />
                  }
                  label={"Add Tax to Total"}
                />
              )}
            </div>
          </div>

          <div className="input-wrapper">
            <div className="notes-section">
              <InputLabel>Notes</InputLabel>
              <TextareaAutosize
                label={"Notes"}
                aria-label="Notes"
                style={{
                  height:
                    !taxRules.taxExempt && taxRate && amount > 0
                      ? "90%"
                      : "85%",
                }}
              />
            </div>
            <div className="price-summary">
              <div>
                <div className="price-breakdown">
                  <span>Base Amount</span>
                  <span>{formatCurrency(amount)}</span>
                </div>
                {!taxRules.taxExempt && taxRate && amount > 0 && (
                  <div className="price-breakdown">
                    <span>Tax %{taxRate}</span>
                    <span>
                      {formatCurrency(amount * (taxRate / 100).toFixed(2))}
                    </span>
                  </div>
                )}
              </div>
              <div className="price-breakdown subtotal">
                <span>Subtotal</span>
                <span>
                  {taxRules.taxToTotal
                    ? formatCurrency(
                        amount + amount * (taxRate / 100).toFixed(2),
                      )
                    : formatCurrency(amount)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;

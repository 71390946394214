import React, { useState, useEffect } from "react";
import { validationRules } from "../../../../constants/validationRules";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import FormattedTextField from "../../../ReactHookForm/FormattedTextField";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext } from "react-hook-form";
import "./processors.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

import Grid from "@mui/material/Grid";
import { timezones } from "../../../../constants/general";
import { MILITARY_TIMES } from "../../../../constants/global";
import PropTypes from "prop-types";
import { regexPatterns } from "../../../../constants/regexPatterns";

function GatewayProcessorDetails({ gatewayInfo, selectedProcessor, isEdit }) {
  const methods = useFormContext();

  const [paymentAdjOption, setPaymentAdjOption] = useState(
    selectedProcessor?.paymentAdjustmentTypeId || "NoAdj",
  );

  useEffect(() => {
    const radioOptions = [
      "isDefaultCard",
      "isDefaultAch",
      "features.hideInVirtualTerminal",
      "features.disableAutoSettle",
    ];
    radioOptions.forEach((option) => {
      methods.register(option);
      if (selectedProcessor) {
        if (!option.includes("features")) {
          methods.setValue(option, selectedProcessor[option]);
        } else {
          let fieldArray = option.split(".");
          methods.setValue(
            `features.${fieldArray[1]}`,
            selectedProcessor.features === undefined
              ? false
              : selectedProcessor.features[fieldArray[1]],
          );
        }
      }
    });
  }, []);

  const formatSettledAt = () => {
    return selectedProcessor
      ? `${
          selectedProcessor?.hour.toString().length > 1
            ? selectedProcessor?.hour
            : "0" + selectedProcessor?.hour
        }:${
          selectedProcessor?.minute.toString().length > 1
            ? selectedProcessor?.minute
            : "0" + selectedProcessor?.minute
        }:00`
      : MILITARY_TIMES[0];
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={10} sx={{ marginBottom: "10px" }}>
          <RegisteredTextField
            id="processor_name"
            name={"name"}
            label="Processor Name"
            fullWidth
            errors={methods.errors}
            defaultValue={selectedProcessor?.name || ""}
            viewOnly={!isEdit}
            rules={{
              pattern: validationRules.alphanumericSpace,
              maxLength: validationRules.maxLength100,
              required: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: "10px" }} spacing={1}>
        <Grid item xs={12} md={5}>
          <RegisteredTextField
            id="group_id"
            name={"groupId"}
            label="Group ID"
            viewOnly={!isEdit}
            defaultValue={selectedProcessor?.groupId}
            fullWidth
            errors={methods.errors}
            rules={{
              pattern: validationRules.numericSpace,
              maxLength: validationRules.maxLength10,
            }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <RegisteredTextField
            id="basys_mid"
            name={"merchantId"}
            label="BASYS MID"
            viewOnly={!isEdit}
            defaultValue={
              selectedProcessor?.merchantId || gatewayInfo?.merchantId
            }
            fullWidth
            errors={methods.errors}
            rules={{
              pattern: validationRules.alphanumericSpace,
              maxLength: validationRules.maxLength50,
            }}
            data-cy="basys-mid-input"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: "10px" }} spacing={1}>
        <Grid item xs={12} md={5}>
          <Select
            control={methods.control}
            name="timezone"
            fullWidth
            id="timezone"
            label="Timezone"
            viewOnly={!isEdit}
            defaultValue={selectedProcessor?.timezone || gatewayInfo.timezone}
          >
            {timezones.map((timezone) => (
              <MenuItem key={`${timezone.value}`} value={timezone.value}>
                {timezone.display}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={5}>
          <Select
            control={methods.control}
            name="settleAt"
            fullWidth
            id="settled_at"
            viewOnly={!isEdit}
            label="Settled At:"
            defaultValue={formatSettledAt()}
          >
            {MILITARY_TIMES.map((time, i) => {
              return (
                <MenuItem key={i} value={time}>
                  {time}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: "10px" }} spacing={1}>
        <Grid item xs={12} md={5}>
          <RegisteredTextField
            id="fns_number"
            name={"foodAndNutritionServiceNumber"}
            label="FNS Number"
            viewOnly={!isEdit}
            defaultValue={selectedProcessor?.foodAndNutritionServiceNumber}
            fullWidth
            errors={methods.errors}
            rules={{
              pattern: validationRules.numericSpace,
              maxLength: validationRules.maxLength10,
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} sx={{ marginBottom: "10px" }}>
          <RegisteredTextField
            id="tag"
            name={"tag"}
            label="Tag"
            viewOnly={!isEdit}
            fullWidth
            errors={methods.errors}
            defaultValue={selectedProcessor?.tag || ""}
            rules={{
              maxLength: { value: 50, message: "Max amount is 50 characters" },
              pattern: regexPatterns.alphanumericSpace,
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: "10px" }} spacing={1}>
        <Grid item xs={12} md={3}>
          <Select
            control={methods.control}
            name="paymentAdjustmentType"
            fullWidth
            viewOnly={!isEdit}
            id="payment_adj"
            defaultValue={selectedProcessor?.paymentAdjustmentTypeId || "NoAdj"}
            label="Payment Adjustment"
            onChange={(e) => {
              setPaymentAdjOption(e);
            }}
          >
            <MenuItem value={"NoAdj"}>Select an option...</MenuItem>
            <MenuItem value={"Percentage"}>Percentage</MenuItem>
            <MenuItem value={"Flat"}>Flat</MenuItem>
          </Select>
        </Grid>
        {paymentAdjOption !== "NoAdj" && (
          <Grid item xs={12} md={3}>
            {" "}
            <FormattedTextField
              type={
                paymentAdjOption.toLowerCase() === "flat"
                  ? "currency"
                  : paymentAdjOption
              }
              viewOnly={!isEdit}
              name="paymentAdjustmentValue"
              defaultValue={
                paymentAdjOption.toLowerCase() === "flat"
                  ? selectedProcessor?.paymentAdjustmentValue * 100 || "0"
                  : selectedProcessor?.paymentAdjustmentValue * 1000 || "0"
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ marginBottom: "10px" }}>
        <Grid item xs={6} md={2}>
          <FormControl>
            <FormLabel id="default-card-radio-label">Default Card</FormLabel>
            <RadioGroup
              aria-labelledby="default-card-radio-label"
              defaultValue={selectedProcessor?.isDefaultCard || false}
              control={methods.control}
              name="isDefaultCard"
              onChange={(e) =>
                methods.setValue("isDefaultCard", e.target.value === "true")
              }
            >
              <FormControlLabel
                labelPlacement="end"
                value={true}
                control={<Radio />}
                label="Yes"
                disabled={!isEdit}
              />
              <FormControlLabel
                labelPlacement="end"
                value={false}
                control={<Radio />}
                label="No"
                disabled={!isEdit}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl>
            <FormLabel id="default-ach-radio-label">Default ACH</FormLabel>
            <RadioGroup
              aria-labelledby="default-ach-radio-label"
              defaultValue={selectedProcessor?.isDefaultAch || false}
              name="isDefaultAch"
              onChange={(e) =>
                methods.setValue("isDefaultAch", e.target.value === "true")
              }
            >
              <FormControlLabel
                labelPlacement="end"
                value={true}
                control={<Radio />}
                label="Yes"
                disabled={!isEdit}
              />
              <FormControlLabel
                labelPlacement="end"
                value={false}
                control={<Radio />}
                label="No"
                disabled={!isEdit}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl>
            <FormLabel id="hide-vt-radio-label">
              Hide on Virtual Terminal
            </FormLabel>
            <RadioGroup
              aria-labelledby="hide-vt-radio-label"
              defaultValue={
                selectedProcessor?.features?.hideInVirtualTerminal || false
              }
              onChange={(e) =>
                methods.setValue(
                  "features.hideInVirtualTerminal",
                  e.target.value === "true",
                )
              }
            >
              <FormControlLabel
                labelPlacement="end"
                value={true}
                control={<Radio />}
                label="Yes"
                disabled={!isEdit}
              />
              <FormControlLabel
                labelPlacement="end"
                value={false}
                control={<Radio />}
                label="No"
                disabled={!isEdit}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl>
            <FormLabel id="auto-settle-radio-label">
              Disable Auto Settle
            </FormLabel>
            <RadioGroup
              aria-labelledby="auto-settle-radio-label"
              defaultValue={
                selectedProcessor?.features?.disableAutoSettle || false
              }
              onChange={(e) =>
                methods.setValue(
                  "features.disableAutoSettle",
                  e.target.value === "true",
                )
              }
            >
              <FormControlLabel
                labelPlacement="end"
                value={true}
                control={<Radio />}
                label="Yes"
                disabled={!isEdit}
              />
              <FormControlLabel
                labelPlacement="end"
                value={false}
                control={<Radio />}
                label="No"
                disabled={!isEdit}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
GatewayProcessorDetails.propTypes = {
  gatewayInfo: PropTypes.object,
  selectedProcessor: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default GatewayProcessorDetails;

import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import Switch from "../../ReactHookForm/Switch";
import { validationRules } from "../../../constants/validationRules";
import Select from "../../ReactHookForm/Select";
import { Modal, Box, MenuItem, Grid, Button } from "@mui/material";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import { stringFormat } from "../../../utils/stringHelpers";
import { regexPatterns } from "../../../constants/regexPatterns";
import { useEffect, useState } from "react";
import "../../../assets/v2/main.scss";
import { useTheme } from "@emotion/react";
import "./terminal.scss";

const CreateTerminalModel = ({
  create,
  terminalTypes,
  setCreate,
  setShowBackdrop,
  showSnackbar,
  gatewayInfo,
  processors,
  setRefreshData,
}) => {
  const methods = useForm();
  const [processorId, setProcessorId] = useState(null);
  const [createError, setCreateError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!create) {
      methods.reset();
      setCreateError(false);
    }
  }, [create]);

  const createTerminal = () => {
    setShowBackdrop(true);
    const payload = {
      name: methods.getValues().terminal_name,
      sourceTerminalId: methods.getValues().terminal_id,
      terminalTypeId: methods.getValues().model,
      serialNumber: methods.getValues().serial_number,
      activateOnCreation: methods.getValues().activate,
      tipEnabled: methods.getValues().tipEnabled,
    };
    let url = stringFormat(sassEndpoints.terminal.postTerminal, [
      gatewayInfo.gatewayId,
      processorId,
    ]);
    axios
      .post(url, payload)
      .then(() => {
        showSnackbar("Successfully added the terminal");
        setCreate(false);
        setRefreshData(true);
      })
      .catch(() => {
        setCreateError(true);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handleSelected = (e, field) => {
    methods.setValue(field, e);
  };
  return (
    <FormProvider {...methods}>
      <Modal
        open={create}
        aria-labelledby="add-new-terminal"
        className={theme.palette.mode === "dark" ? "dark-theme" : ""}
      >
        <Box className="v2 create-terminal-modal">
          <Grid
            container
            flexDirection={"column"}
            justifyContent="space-between"
            style={{ height: "100%" }}
            rowSpacing={2}
          >
            <Grid item>Add New Terminal</Grid>
            <Grid item>
              Please make sure that terminal is powered on and connected to the
              internet.
            </Grid>
            <Grid item>
              <RegisteredTextField
                name="terminal_name"
                label="Name"
                fullWidth
                placeholder="Friendly name for the terminal"
                rules={{
                  required:
                    "Please enter a friendly terminal name under 50 characters.",

                  maxLength: validationRules.maxLength50,
                }}
              >
                Name
              </RegisteredTextField>
            </Grid>
            <Grid item>
              <RegisteredTextField
                name="serial_number"
                label="Serial Number"
                fullWidth
                placeholder="Scan or input the terminal’s serial number."
                rules={{
                  required:
                    "Please enter a serial number under 255 characters.",
                  maxLength: validationRules.maxLength255,
                }}
              >
                Serial Number
              </RegisteredTextField>
            </Grid>
            <Grid item>
              <RegisteredTextField
                name="terminal_id"
                label="Terminal ID"
                fullWidth
                placeholder="Fiserv terminal Id"
                rules={{
                  pattern: {
                    value: regexPatterns.numeric,
                    message: "Terminal ID may only contain numbers",
                  },
                  required: "Please enter a  TID between 8 to 50 characters.",
                  maxLength: validationRules.maxLength50,
                  minLength: {
                    value: 8,
                    message: "Please enter a  TID between 8 to 50 characters.",
                  },
                }}
              >
                Terminal ID
              </RegisteredTextField>
            </Grid>
            <Grid item>
              <Select
                control={methods.control}
                name="model"
                label="Model"
                fullWidth
                placeholder="Select a model"
                {...methods.register("model", { required: true })}
                onChange={(e) => handleSelected(e, "model")}
                rules={{
                  required: "Please select a model.",
                }}
              >
                {terminalTypes.map((terminal, i) => (
                  <MenuItem key={i} value={terminal.terminalTypeId}>
                    {terminal.model}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Select
                control={methods.control}
                name="processor"
                label="Processor"
                fullWidth
                placeholder="Select a processor"
                {...methods.register("processor", { required: true })}
                onChange={(e) => {
                  setProcessorId(e);
                }}
                rules={{
                  required: "Please select a processor.",
                }}
              >
                {processors.map((p, i) => (
                  <MenuItem key={i} value={p.processorId}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Switch
                name="activate"
                label="Activate on create"
                defaultValue="true"
              >
                Activate on create
              </Switch>
            </Grid>
            <Grid item>
              <Switch
                name="tipEnabled"
                label="Tip enabled"
                defaultValue={false}
                defaultChecked={false}
              >
                Tip enabled
              </Switch>
            </Grid>
            <Grid item>
              {createError && (
                <div className="caption create-error-msg">
                  An error has occurred while processing your request, please
                  try again!
                </div>
              )}
            </Grid>
            <Grid item>
              {" "}
              <div className="btn-grp">
                <Button
                  className="btn--secondary"
                  onClick={() => {
                    setCreate(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn--primary"
                  style={{ marginLeft: "10px" }}
                  onClick={async () => {
                    const noErrors = await methods.trigger();
                    if (
                      noErrors &&
                      methods.getValues().serial_number.length > 0 &&
                      methods.getValues().terminal_id.length > 0 &&
                      methods.getValues().terminal_name.length > 0
                    ) {
                      createTerminal();
                    }
                  }}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </FormProvider>
  );
};

CreateTerminalModel.propTypes = {
  create: PropTypes.boolean,
  terminalTypes: PropTypes.any,
  setCreate: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  showSnackbar: PropTypes.func,
  gatewayInfo: PropTypes.any,
  processors: PropTypes.any,
  setRefreshData: PropTypes.func,
};

export default CreateTerminalModel;

/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../constants/validationRules";
import Select from "../../../ReactHookForm/Select";
import { addDaysToDate, differenceInDays } from "../../../../utils/dateHelpers";
import LabelWithTooltip from "./LabelWithTooltip";
import { parseISO } from "date-fns";
import CustomFields from "../../CustomFields/GlobalCustomFields";
import CustomFieldDetails from "../../CustomFields/CustomFieldDetails";

export const formatDate = (date) => {
  var localDate = parseISO(date);
  var newDate = new Date(
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
  );

  return newDate.toLocaleDateString(
    "en-CA",
    Intl.DateTimeFormat().resolvedOptions(),
  );
};

const InvoiceStepTwo = ({
  setActiveStep,
  onPageChange,
  invoice,
  processors,
  mode,
  onEdit,
  onEditEnter,
  onEditFail,
  customFieldGroups,
  chosenCustomFields,
}) => {
  const cardProcessors = processors.filter(
    (p) => p.processorType.type === "CreditCard" && p.settings,
  );
  const achProcessors = processors.filter(
    (p) => p.processorType.type === "Ach" && p.settings,
  );

  const [card, setCard] = useState(
    invoice?.invoicePaymentMethod
      ? invoice.invoicePaymentMethod.card
      : cardProcessors.length > 0,
  );
  const [ach, setAch] = useState(
    invoice?.invoicePaymentMethod
      ? invoice.invoicePaymentMethod.ach
      : achProcessors.length > 0 &&
          achProcessors.some((p) =>
            p.features?.availableSecCodes?.some(
              (s) => s.toLowerCase() === "web",
            ),
          ),
  );
  const [displayDueDateWarning, setDisplayWarning] = useState(false);

  const methods = useForm({
    defaultValues: {
      invoicePaymentMethod: {
        card,
        ach,
        cardProcessorId:
          invoice?.invoicePaymentMethod?.cardProcessorId ||
          cardProcessors.find((p) => p.isDefaultCard)?.processorId,
        achProcessorId:
          invoice?.invoicePaymentMethod?.achProcessorId ||
          achProcessors.find((p) => p.isDefaultAch)?.processorId,
      },
    },
  });

  const calculateTerm = () => {
    if (invoice?.invoiceDate) {
      const diff = differenceInDays(
        formatDate(invoice.invoiceDate),
        formatDate(invoice.dueDate),
      );

      if (diff !== 15 && diff !== 30 && diff !== 60) return 0;
      else return diff;
    }

    return 30;
  };

  const term = useWatch({
    control: methods.control,
    name: "term",
    defaultValue: calculateTerm(),
  });

  const invoiceDate = useWatch({
    control: methods.control,
    name: "invoiceDate",
    defaultValue: formatDate(invoice?.invoiceDate || new Date().toISOString()),
  });

  const dueDate = useWatch({
    control: methods.control,
    name: "dueDate",
    defaultValue: formatDate(
      invoice?.dueDate ||
        addDaysToDate(new Date().toISOString(), 30).toISOString(),
    ),
  });

  const invoicePaymentMethod = useWatch({
    control: methods.control,
    name: "invoicePaymentMethod",
    defaultValue: invoice?.invoicePaymentMethod,
  });

  useEffect(() => {
    if (term !== 0) {
      methods.setValue(
        "dueDate",
        formatDate(addDaysToDate(invoiceDate, term).toISOString()),
      );
    } else if (
      !invoice.dueDate ||
      (invoice.dueDate &&
        formatDate(invoice.dueDate) !== methods.getValues("dueDate"))
    ) {
      methods.setValue(
        "dueDate",
        formatDate(
          invoice.dueDate || addDaysToDate(invoiceDate, 7).toISOString(),
        ),
      );
    }
  }, [term, invoiceDate]);

  useEffect(() => {
    setDisplayWarning(differenceInDays(new Date().toISOString(), dueDate) < 0);
  }, [dueDate]);

  const handlePaymentTypeChange = (e) => {
    if (e.target.name === "ACH") {
      setAch(e.target.checked);
      if (!e.target.checked) setCard(true);
    } else {
      setCard(e.target.checked);
      if (!e.target.checked) setAch(true);
    }
  };

  const handleCustomFields = () => {
    let customFields = [];
    let customFieldEntries = [];
    let chosenGroup = methods.getValues("customer_field_group_select");

    if (chosenGroup) {
      methods
        .getValues("custom_fields")
        [chosenGroup].forEach((fieldValue, index) => {
          if (fieldValue !== null && fieldValue.length > 0) {
            let customFieldName, customFieldType;

            chosenCustomFields.forEach((category) => {
              let customField = category.find(
                (cf) => cf.customFieldId === index,
              );

              if (customField) {
                customFieldName = customField.name;
                customFieldType = customField.type;

                return;
              }
            });

            if (
              customFieldType === "Checklist" ||
              customFieldType === "Multiselect"
            ) {
              fieldValue.forEach((value) => {
                customFieldEntries.push({
                  customFieldId: index,
                  customFieldCategoryName: chosenGroup,
                  name: customFieldName,
                  value,
                  type: customFieldType,
                });
              });
            } else {
              customFieldEntries.push({
                customFieldId: index,
                customFieldCategoryName: chosenGroup,
                name: customFieldName,
                value: fieldValue,
                type: customFieldType,
              });
            }

            customFields.push({
              customFieldId: index,
              customFieldValue:
                //field value here is required to be type [string]
                typeof fieldValue === "object" ? fieldValue : [fieldValue],
            });
          }
        });
    }
    return { customFields, customFieldEntries };
  };

  const handlePageChange = (
    { title, number, invoicePaymentMethod, message },
    step,
  ) => {
    let { cardProcessorId, achProcessorId } = invoicePaymentMethod || {};

    if (card) {
      if (cardProcessors.length === 1)
        cardProcessorId = cardProcessors[0].processorId;
    } else {
      cardProcessorId = null;
    }

    if (ach) {
      if (achProcessors.length === 1)
        achProcessorId = achProcessors[0].processorId;
    } else {
      achProcessorId = null;
    }

    const { customFields, customFieldEntries } = handleCustomFields();

    const invoice = {
      title,
      number: number.length ? number : null,
      invoiceDate,
      dueDate,
      invoicePaymentMethod: {
        card,
        ach,
        cardProcessorId,
        achProcessorId,
        secCode: 2, // Default to WEB Sec Code
      },
      message,
      customFields,
      customFieldEntries,
    };

    onPageChange(invoice);
    setActiveStep(step);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(event) => event.preventDefault()}
        onFocus={onEditEnter}
        onBlur={
          onEdit && Object.keys(methods.formState.errors).length === 0
            ? methods.handleSubmit((values) => {
                onEdit(
                  {
                    ...values,
                    invoicePaymentMethod: {
                      ...invoicePaymentMethod,
                      achProcessorId:
                        achProcessors.length === 1
                          ? achProcessors[0].processorId
                          : invoicePaymentMethod.achProcessorId,
                      cardProcessorId:
                        cardProcessors.length === 1
                          ? cardProcessors[0].processorId
                          : invoicePaymentMethod.cardProcessorId,
                    },
                  },
                  methods.formState.dirtyFields,
                  card,
                  ach,
                  handleCustomFields().customFields,
                );
              }, onEditFail)
            : null
        }
      >
        <Grid container spacing={1}>
          <Grid
            item
            container
            spacing={1}
            paddingRight={1}
            className="invoice-section invoice-details"
          >
            <Grid item container spacing={1} marginBottom={1} paddingRight={1}>
              <Grid item xs={12} sm={6}>
                <RegisteredTextField
                  id="title"
                  name="title"
                  label={
                    <LabelWithTooltip
                      label="Invoice Title"
                      tooltip="The Invoice Title displays in the Invoice Email Subject Line"
                    />
                  }
                  defaultValue={invoice?.title}
                  fullWidth
                  viewOnly={mode === "view"}
                  showRequired={false}
                  rules={{
                    maxLength: validationRules.maxLength100,
                    required: {
                      value: true,
                      message: "Invoice Title is required",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={mode === "create" ? 3 : 6}>
                <RegisteredTextField
                  id="number"
                  name="number"
                  label="Invoice Number"
                  defaultValue={invoice?.number}
                  fullWidth
                  viewOnly={mode === "view"}
                  rules={{
                    maxLength: validationRules.maxLength50,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1} paddingRight={1}>
              <Grid item xs={12} sm={mode === "create" ? 3 : 4}>
                <Select
                  control={methods.control}
                  label="Terms"
                  name="term"
                  fullWidth
                  viewOnly={mode === "view"}
                  defaultValue={calculateTerm()}
                  data-cy="terms"
                >
                  <MenuItem value={0}>Due On Receipt</MenuItem>
                  <MenuItem value={15}>Net 15</MenuItem>
                  <MenuItem value={30}>Net 30</MenuItem>
                  <MenuItem value={60}>Net 60</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={mode === "create" ? 3 : 4}>
                <TextField
                  control={methods.control}
                  type="date"
                  inputProps={{ max: formatDate(new Date().toISOString()) }}
                  label="Invoice Date"
                  name="invoiceDate"
                  value={invoiceDate}
                  fullWidth
                  disabled={mode === "view"}
                  variant={mode === "view" ? "standard" : "outlined"}
                  InputProps={mode === "view" ? { disableUnderline: true } : {}}
                  onChange={(e) =>
                    methods.setValue(
                      "invoiceDate",
                      new Date(e.target.value).toISOString().split("T")[0],
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={mode === "create" ? 3 : 4}>
                <TextField
                  control={methods.control}
                  type="date"
                  label="Due Date"
                  name="dueDate"
                  value={dueDate}
                  fullWidth
                  disabled={term !== 0 || mode === "view"}
                  variant={mode === "view" ? "standard" : "outlined"}
                  InputProps={mode === "view" ? { disableUnderline: true } : {}}
                  onChange={(e) =>
                    methods.setValue(
                      "dueDate",
                      new Date(e.target.value).toISOString().split("T")[0],
                    )
                  }
                />
              </Grid>
            </Grid>
            {mode !== "view" && (
              <Grid item xs={12} sm={mode === "create" ? 9 : 12}>
                <Collapse in={displayDueDateWarning}>
                  <Alert severity="warning">
                    Due date is prior to today&apos;s date
                  </Alert>
                </Collapse>
              </Grid>
            )}
            {mode !== "view" &&
              customFieldGroups?.length > 0 &&
              chosenCustomFields !== null && (
                <Grid
                  item
                  xs={12}
                  sm={mode === "create" ? 6 : 12}
                  paddingRight={1}
                >
                  <CustomFields
                    customFieldGroups={customFieldGroups}
                    chosenCustomFields={chosenCustomFields}
                    customerFieldNameAndValues={
                      invoice?.customFieldEntries?.length > 0
                        ? [
                            invoice?.customFieldEntries[0]
                              ?.customFieldCategoryName,
                            invoice?.customFieldEntries,
                            mode !== "view",
                          ]
                        : null
                    }
                  />
                </Grid>
              )}
            {mode === "view" && invoice?.customFieldEntries?.length > 0 && (
              <CustomFieldDetails data={invoice} fieldBoldness="600" />
            )}
          </Grid>
          <Grid item container className="invoice-section payment-types">
            <Grid item container marginLeft={1} marginBottom={4}>
              <Grid item xs={12}>
                <Typography variant="body1" className="payment-types-heading">
                  Available Payment Types
                </Typography>
              </Grid>
              {cardProcessors.length > 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={5}
                  paddingRight={4}
                  className="invoice-payment-type-toggle"
                  sx={{
                    borderRight:
                      achProcessors.length > 0 ? "1px solid #ddd;" : "none",
                  }}
                >
                  {achProcessors.length > 0 && (
                    <Grid item xs={12} marginBottom={3}>
                      <FormControlLabel
                        name="card"
                        label="Card"
                        labelPlacement="start"
                        control={
                          <Switch
                            color={mode !== "view" ? "secondary" : "default"}
                          />
                        }
                        checked={card}
                        onChange={handlePaymentTypeChange}
                        disabled={
                          mode === "view" ||
                          !achProcessors.some((p) =>
                            p.features?.availableSecCodes?.some(
                              (s) => s.toLowerCase() === "web",
                            ),
                          )
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {cardProcessors.length > 1 ? (
                      <Select
                        control={methods.control}
                        label="Processor"
                        name="invoicePaymentMethod.cardProcessorId"
                        defaultValue={
                          invoice?.invoicePaymentMethod?.cardProcessorId ||
                          cardProcessors.find((p) => p.isDefaultCard)
                            ?.processorId
                        }
                        rules={{ required: card }}
                        fullWidth
                        viewOnly={mode === "view"}
                        disabled={!card}
                      >
                        {cardProcessors.map((processor) => (
                          <MenuItem
                            key={processor.processorId}
                            value={processor.processorId}
                          >
                            {processor.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <p className={!card ? "disabled" : undefined}>
                        <strong>
                          {achProcessors.length === 0 && "Card "}Processor:{" "}
                          {cardProcessors[0].name}
                        </strong>
                      </p>
                    )}
                  </Grid>
                </Grid>
              )}
              {achProcessors.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={mode === "create" ? 5 : 6}
                  paddingRight={4}
                  className="invoice-payment-type-toggle"
                >
                  {cardProcessors.length > 0 && (
                    <Grid item xs={12} marginBottom={3}>
                      <FormControlLabel
                        name="ACH"
                        label="ACH"
                        labelPlacement="start"
                        control={
                          <Switch
                            color={mode !== "view" ? "secondary" : "default"}
                          />
                        }
                        checked={ach}
                        onChange={handlePaymentTypeChange}
                        disabled={
                          mode === "view" ||
                          !achProcessors.some((p) =>
                            p.features?.availableSecCodes?.some(
                              (s) => s.toLowerCase() === "web",
                            ),
                          )
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} marginBottom={2}>
                    {achProcessors.length > 1 ? (
                      <Select
                        control={methods.control}
                        label="Processor"
                        name="invoicePaymentMethod.achProcessorId"
                        defaultValue={
                          invoice?.invoicePaymentMethod?.achProcessorId ||
                          achProcessors.find(
                            (p) =>
                              p.isDefaultAch &&
                              p.features?.availableSecCodes?.some(
                                (s) => s.toLowerCase() === "web",
                              ),
                          )?.processorId
                        }
                        rules={{ required: ach }}
                        fullWidth
                        viewOnly={mode === "view"}
                        disabled={!ach}
                      >
                        {achProcessors.map((processor) => (
                          <MenuItem
                            key={processor.processorId}
                            value={processor.processorId}
                            disabled={
                              !processor.features?.availableSecCodes?.some(
                                (s) => s.toLowerCase() === "web",
                              )
                            }
                          >
                            {processor.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : achProcessors[0].features?.availableSecCodes?.some(
                        (s) => s.toLowerCase() === "web",
                      ) ? (
                      <p className={!ach ? "disabled" : undefined}>
                        <strong>
                          {cardProcessors.length === 0 && "ACH "}Processor:{" "}
                          {achProcessors[0].name}
                        </strong>
                      </p>
                    ) : (
                      <Alert severity="warning" className="no-ach-processor">
                        ACH payments unavailable: No processor configured with
                        WEB SEC code
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={mode === "create" ? 11 : 12}
              paddingLeft={1}
              paddingRight={mode === "create" ? 4 : 1}
            >
              <RegisteredTextField
                id="message"
                name="message"
                label={
                  <LabelWithTooltip
                    label="Message"
                    tooltip="This message displays on the invoice"
                  />
                }
                defaultValue={invoice?.message}
                className="invoice-message"
                fullWidth
                multiline
                viewOnly={mode === "view"}
                rows={5}
                rules={{
                  maxLength: validationRules.maxLength500,
                }}
                data-cy='invoice-message'
              />
            </Grid>
          </Grid>
          {mode === "create" && (
            <Grid item container className="next-page-button">
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handlePageChange(methods.getValues(), 0)}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item paddingLeft={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  data-cy="next-step"
                  onClick={methods.handleSubmit((values) =>
                    handlePageChange(values, 2),
                  )}
                >
                  Next Step
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

InvoiceStepTwo.propTypes = {
  setActiveStep: PropTypes.func,
  onPageChange: PropTypes.func,
  onEdit: PropTypes.func,
  onEditEnter: PropTypes.func,
  onEditFail: PropTypes.func,
  invoice: PropTypes.object,
  processors: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.oneOf(["create", "edit", "view"]),
  customFieldGroups: PropTypes.arrayOf(PropTypes.string),
  chosenCustomFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
};

InvoiceStepTwo.defaultProps = {
  mode: "create",
};

export default InvoiceStepTwo;

import { Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { sassEndpoints } from "../../../constants/endpoints";
import { stringFormat } from "../../../utils/stringHelpers";
import axios from "axios";
import { useTheme } from "@mui/material";

const TerminalMenu = ({
  open,
  terminalObj,
  gatewayInfo,
  setShowBackdrop,
  showError,
  anchorEl,
  setAlertDialogOpen,
  setAlertDialogProps,
  handleClose,
  showSnackbar,
  getTerminalData,
}) => {
  const theme = useTheme();

  const activateTerminal = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.terminal.activateTerminal, [
      gatewayInfo.gatewayId,
      terminalObj?.processor?.processorId,
      terminalObj?.terminalId,
    ]);
    axios
      .put(url)
      .then(() => {
        showSnackbar("Successfully activate terminal!");
        getTerminalData(true, terminalObj?.terminalId);
        handleClose();
      })
      .catch(() => {
        showError("Error activating terminal");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const restartTerminal = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.terminal.restart, [
      gatewayInfo.gatewayId,
      terminalObj?.processor?.processorId,
      terminalObj?.terminalId,
    ]);
    axios
      .put(url)
      .then(() => {
        showSnackbar("Successfully restarted terminal!");
        handleClose();
      })
      .catch(() => {
        showError("Error restarting terminal");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const updateSoftware = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.terminal.updateSoftware, [
      gatewayInfo.gatewayId,
      terminalObj?.processor?.processorId,
      terminalObj?.terminalId,
    ]);
    axios
      .put(url, {
        FirmwareVersion: null,
        BasysAppVersion: null,
      })
      .then(() => {
        showSnackbar("Successfully updated software!");
        handleClose();
      })
      .catch(() => {
        showError("Error updating software");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const reportStolen = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.terminal.reportStolen, [
      gatewayInfo.gatewayId,
      terminalObj?.processor?.processorId,
      terminalObj?.terminalId,
    ]);
    axios
      .put(url)
      .then(() => {
        setAlertDialogProps({});
        setAlertDialogOpen(false);
        getTerminalData(true, terminalObj?.terminalId);
        handleClose();
        showSnackbar("Terminal locked.");
      })
      .catch(() => {
        showError("Failed to lock terminal.");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const reportRecovered = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.terminal.reportRecovered, [
      gatewayInfo.gatewayId,
      terminalObj?.processor?.processorId,
      terminalObj?.terminalId,
    ]);
    axios
      .put(url)
      .then(() => {
        setAlertDialogProps({});
        setAlertDialogOpen(false);
        getTerminalData(true, terminalObj?.terminalId);
        handleClose();
        showSnackbar("Terminal unlocked.");
      })
      .catch(() => {
        showError("Failed to unlock terminal.");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const deleteTerminal = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.terminal.terminalId, [
      gatewayInfo.gatewayId,
      terminalObj?.processor?.processorId,
      terminalObj?.terminalId,
    ]);
    axios
      .delete(url)
      .then(() => {
        setAlertDialogProps({});
        setAlertDialogOpen(false);
        getTerminalData(true);
        handleClose();
        showSnackbar("Successfully deleted terminal!");
      })
      .catch(() => [showError("Error deleting terminal")])
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  return (
    <Menu
      className={theme.palette.mode === "dark" ? "dark-theme menu" : "menu"}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "options-menu",
      }}
    >
      <MenuItem
        onClick={() => {
          activateTerminal();
        }}
        className={
          terminalObj?.status?.toLowerCase() !== "pending" && "Mui-disabled"
        }
      >
        Activate
      </MenuItem>
      <MenuItem
        onClick={() => {
          restartTerminal();
        }}
      >
        Restart
      </MenuItem>
      <MenuItem
        onClick={() => {
          updateSoftware();
        }}
      >
        Update Software
      </MenuItem>
      <MenuItem
        className={
          terminalObj?.status?.toLowerCase() === "locked" && "Mui-disabled"
        }
        onClick={() => {
          setAlertDialogProps({
            alertTitle: "Lock the terminal?",
            alertLevel: "info",
            alertMessages: [
              "The terminal will not be accessible to process transactions until it is unlocked.",
            ],
            actionButtons: [
              {
                text: "Yes",
                color: "primary",
                onclick: () => {
                  reportStolen();
                },
              },
            ],
            closeButtonText: "Cancel",
            closeButtonColor: "neutrals",
            onCloseButtonClick: () => {
              setAlertDialogProps({});
              setAlertDialogOpen(false);
              handleClose();
            },
          });
          setAlertDialogOpen(true);
        }}
      >
        Lock
      </MenuItem>
      <MenuItem
        className={
          terminalObj?.status?.toLowerCase() !== "locked" && "Mui-disabled"
        }
        onClick={() => {
          setAlertDialogProps({
            alertTitle: "Unlock the terminal?",
            alertLevel: "info",
            alertMessages: [
              "The terminal will be able to process transactions again.",
            ],
            actionButtons: [
              {
                text: "Yes",
                color: "primary",
                onclick: () => {
                  reportRecovered();
                },
              },
            ],
            closeButtonText: "Cancel",
            closeButtonColor: "neutrals",
            onCloseButtonClick: () => {
              setAlertDialogProps({});
              setAlertDialogOpen(false);
              handleClose();
            },
          });
          setAlertDialogOpen(true);
        }}
      >
        Unlock
      </MenuItem>
      <MenuItem
        onClick={() => {
          setAlertDialogProps({
            alertTitle: "Are you sure?",
            alertLevel: "info",
            alertMessages: ["Are you sure you want to delete the terminal?"],
            actionButtons: [
              {
                text: "Yes",
                color: "primary",
                onclick: () => {
                  deleteTerminal();
                },
              },
            ],
            closeButtonText: "Cancel",
            closeButtonColor: "neutrals",
            onCloseButtonClick: () => {
              setAlertDialogProps({});
              setAlertDialogOpen(false);
              handleClose();
            },
          });
          setAlertDialogOpen(true);
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );
};

TerminalMenu.propTypes = {
  open: PropTypes.bool,
  terminalObj: PropTypes.object,
  gatewayInfo: PropTypes.any,
  showSnackbar: PropTypes.func,
  showError: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  setAlertDialogProps: PropTypes.func,
  setAlertDialogOpen: PropTypes.func,
  handleClose: PropTypes.func,
  anchorEl: PropTypes.func,
  getTerminalData: PropTypes.func,
};
export default TerminalMenu;
